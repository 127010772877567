<template>
  <div class="page">
    <el-tabs v-model="activeName" @tab-click="changeTab">
      <el-tab-pane label="基本资料" name="first">
        <div class="submitForm">
          <el-form ref="form" :model="submitForm" label-position="top" label-width="140px">
            <el-row>
             <el-descriptions title="用户信息" :column="2">
               <el-descriptions-item label="姓名">{{submitForm.userName || '--'}}</el-descriptions-item>
               <el-descriptions-item style="margin-top: 30px" label="联系电话">{{submitForm.phone || '--'}}</el-descriptions-item>
               <el-descriptions-item label="出生日期">{{submitForm.birthday || '--'}}</el-descriptions-item>
               <el-descriptions-item label="年龄">{{submitForm.old || '--'}}</el-descriptions-item>
               <el-descriptions-item label="性别"> {{['女', '男', '未知'][submitForm.sex*1]}}</el-descriptions-item>
               <el-descriptions-item label="民族">{{submitForm.nation || '--'}}</el-descriptions-item>
               <el-descriptions-item label="学历">{{submitForm.cultureDegreeText || '--'}}</el-descriptions-item>
               <el-descriptions-item label="老人类型">{{['', '本区老人', '非本区老人'][submitForm.mzeeType*1]}}</el-descriptions-item>
             </el-descriptions>
           </el-row>
            <el-row style="margin-top: 20px">
              <el-descriptions title="身份证" :column="2">
                <el-descriptions-item label="证件类型">{{submitForm.documentTypeText || '--'}}</el-descriptions-item>
                <el-descriptions-item style="margin-top: 30px" label="证件号码">{{submitForm.cardId || '--'}}</el-descriptions-item>
              </el-descriptions>
              <div class="imgs-box">
                <div v-if="submitForm.cardFrontImage" class="imgs">
                  <img :src="submitForm.cardFrontImage" width="200" height="200" alt="">
                  <span>身份证-人像面</span>
                </div>
                <div v-if="submitForm.cardBackImage" class="imgs">
                  <img :src="submitForm.cardBackImage"  width="200" height="200" alt="">
                  <span>身份证-国徽面</span>
                </div>
              </div>
            </el-row>
            <el-row style="margin-top: 35px">
              <el-descriptions title="户籍信息" :column="1">
                <el-descriptions-item label="所属区域">
                 <span v-if="submitForm.province || submitForm.city || submitForm.area || submitForm.community || submitForm.street">
                   {{submitForm.province}}
                  {{submitForm.city}}
                  {{submitForm.area}}
                  {{submitForm.community}}
                  {{submitForm.street}}
                 </span>
                  <span v-else>--</span>
                </el-descriptions-item>
                <el-descriptions-item label="户籍地址">{{submitForm.registeredResidence || '--'}}</el-descriptions-item>
                <el-descriptions-item label="常住地址">{{submitForm.homeAddress || '--'}}</el-descriptions-item>
              </el-descriptions>
              <div class="imgs-box">
                <div v-if="submitForm.residenceFirstImage" class="imgs">
                  <img :src="submitForm.residenceFirstImage" width="200" height="200" alt="">
                  <span>户口簿-户主页(首页)</span>
                </div>
                <div v-if="submitForm.residenceOneselfImage" class="imgs">
                  <img :src="submitForm.residenceOneselfImage" width="200" height="200" alt="">
                  <span>户口簿-本人页</span>
                </div>
                <div v-if="submitForm.residenceFluctuateImage" class="imgs">
                  <img :src="submitForm.residenceFluctuateImage" width="200" height="200" alt="">
                  <span>户口簿-增减页</span>
                </div>
              </div>
            </el-row>
            <el-row style="margin-top: 35px">
              <el-descriptions title="缴费收据" :column="1"/>
              <div v-if="submitForm.receiptImage" class="imgs-box">
                <div v-for="(item, index) in submitForm.receiptImage.split(',')" :key="index"  class="imgs">
                  <img :src="item" alt="">
                </div>
              </div>
              <span v-else>暂无图片</span>
            </el-row>
            <el-row style="margin-top: 35px">
              <el-descriptions title="评估资料" :column="1"/>
              <div v-if="submitForm.evaluationDataImage" class="imgs-box">
                <div v-for="(item, index) in submitForm.evaluationDataImage.split(',')" :key="index" class="imgs">
                  <img :src="item" alt="">
                </div>
              </div>
              <span v-else>暂无图片</span>
            </el-row>
            <el-row style="margin-top: 35px">
              <el-descriptions title="体检资料" :column="1"/>
              <div v-if="submitForm.checkupImage" class="imgs-box">
                <div v-for="(item, index) in submitForm.checkupImage.split(',')" :key="index" class="imgs">
                  <img :src="item" alt="">
                </div>
              </div>
              <span v-else>暂无图片</span>
            </el-row>
            <el-row style="margin-top: 35px">
              <el-descriptions title="银行卡信息" :column="2">
                <el-descriptions-item label="银行卡账号">{{submitForm.bankCard || '--'}}</el-descriptions-item>
                <el-descriptions-item label="开户行">{{submitForm.bankDeposit || '--'}}</el-descriptions-item>
                <el-descriptions-item label="持卡人姓名">{{submitForm.bankUserName || '--'}}</el-descriptions-item>
                <el-descriptions-item label="持卡人与本人关系">{{submitForm.bankRelationship || '--'}}</el-descriptions-item>
              </el-descriptions>
              <div v-if="submitForm.bankImage" class="imgs-box">
                <div class="imgs">
                  <img :src="submitForm.bankImage" alt="">
                  <span>银行卡-正面</span>
                </div>
              </div>
            </el-row>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane label="入住资料" name="second">
        <el-row style="margin-top: 10px">
          <el-descriptions title="" :column="2">
            <el-descriptions-item label="护理级别">{{moveIntoInfoData.nursingLevelDictText || '--'}}</el-descriptions-item>
            <el-descriptions-item label="入住类型">{{['入住', '退住'][moveIntoInfoData.state*1]}}</el-descriptions-item>
            <el-descriptions-item label="入住时间">{{moveIntoInfoData.liveStartTime || '--'}}</el-descriptions-item>
            <el-descriptions-item label="退住时间">{{moveIntoInfoData.retreatTime || '--'}}</el-descriptions-item>
            <el-descriptions-item label="退住原因">{{moveIntoInfoData.retreatReason || '--'}}</el-descriptions-item>
            <el-descriptions-item label="计费起始日">{{moveIntoInfoData.liveStartTime || '--'}}</el-descriptions-item>
            <el-descriptions-item label="合同开始时间">{{moveIntoInfoData.liveStartTime || '--'}}</el-descriptions-item>
            <el-descriptions-item label="合同到期时间">{{moveIntoInfoData.liveStartTime || '--'}}</el-descriptions-item>
            <el-descriptions-item label="楼栋">{{moveIntoInfoData.buildNumber || '--'}}</el-descriptions-item>
            <el-descriptions-item label="楼层">{{moveIntoInfoData.floorNumber || '--'}}</el-descriptions-item>
            <el-descriptions-item label="房号">{{moveIntoInfoData.roomNumber || '--'}}</el-descriptions-item>
            <el-descriptions-item label="床位号">{{moveIntoInfoData.roomBedNumber || '--'}}</el-descriptions-item>
          </el-descriptions>
          <div style="margin-top: 10px">
            <span class="title">月费用价格</span>
            <el-table
              :data="moveIntoInfoData.monthlyFeeList"
              stripe
              border
              show-summary
              :header-cell-style="headerCellStyle"
              style="width: 40%;margin-top: 20px">
              <template slot="empty">
                <IsEmpty />
              </template>
              <el-table-column type="index" label="序号" align="center" width="80" />
              <el-table-column
                prop="expenseName"
                align="center"
                label="费用名称">
              </el-table-column>
              <el-table-column
                prop="monthlyFeePrice"
                align="center"
                label="月">
              </el-table-column>
              <el-table-column
                prop="dailyFeePrice"
                align="center"
                label="日">
              </el-table-column>
            </el-table>
          </div>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="紧急联系人" name="third">
        <el-table
          :data="emergencyContactTableData"
          stripe
          border
          :header-cell-style="headerCellStyle"
          style="width: 100%;margin-top: 20px">
          <template slot="empty">
            <IsEmpty />
          </template>
          <el-table-column type="index" label="序号" align="center" width="80" />
          <el-table-column
            prop="name"
            align="center"
            label="姓名">
          </el-table-column>
          <el-table-column
            prop="relationshipText"
            align="center"
            label="关系">
          </el-table-column>
          <el-table-column
            prop="telephoneNumber"
            align="center"
            label="电话号码">
          </el-table-column>
          <el-table-column
            prop="phone"
            align="center"
            label="手机号码">
          </el-table-column>
          <el-table-column
            prop="documentTypeText"
            align="center"
            label="证件类型">
          </el-table-column>
          <el-table-column
            prop="cardId"
            align="center"
            label="证件号码">
          </el-table-column>
          <el-table-column
            prop="contactPerson"
            align="center"
            label="是否第一联络人">
            <template slot-scope="scope">
              <span>
                {{['否', '是'][scope.row.contactPerson*1]}}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="homeAddress"
            align="center"
            label="家庭地址">
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <div class="btn">
      <el-button style="width:120px;margin-right:30px" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>

<script>
  import { basicInformation, emergencyContacts, moveInto } from "@/api/institutionalSubsidies"
  export default {
    name: "elderlyFormInfoView",
    data() {
      return {
        activeName: 'first',
        rowId: '',
        submitForm: {},
        emergencyContactTableData: [], //紧急联系人
        moveIntoInfoData: {}, //入住资料
      }
    },
    mounted() {
      this.rowId = this.$route.query.id
      this.basicInformationData()
    },
    methods: {
      changeTab(tab) {
        console.log(tab)
        let { name } = tab
        if(name === 'second') {
          this.moveIntoData()
        }else if(name === 'third') {
          this.emergencyContactsData()
        }
      },
      async  basicInformationData() {
        const res = await basicInformation({id:this.rowId})
        this.submitForm = res.data || {}
        if(!this.submitForm.userName) {
          this.submitForm.userName = ''
        }
      },

      async moveIntoData() {
        const res = await moveInto({id:this.rowId})
        this.moveIntoInfoData = res.data || {}
        console.log(res.data, '<<<<<<<<<<<<<<<<<<<')
      },

      async emergencyContactsData() {
        const res = await emergencyContacts({id:this.rowId})
        this.emergencyContactTableData = res.data || []
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page {
    background-color: #ffffff;
    padding: 20px 30px;
    min-height: calc(100vh - 145px);
    box-sizing: border-box;
    .submitForm {
      margin-top: 10px;
      width: 80%;
      .title {
        font-size: 16px;
        font-weight: bold;
      }
      .tip {
        color: #7f7f7f;
        margin-bottom: 10px;
      }
      .tip_bottom {
        line-height: 20px;
      }

      .imgs-box {
        display: flex;
        margin-top: 20px;
        .imgs{
          margin-right: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 250px;
          box-sizing: border-box;
          img {
            width: 200px;
            height: 200px;
          }
          span {
            display: inline-block;
            color: #606266;
            font-size: 14px;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .btn {
    margin-top: 30px;
    margin-left: 40%;
  }
  /deep/ .el-tabs__item {
    font-size: 14px !important;
  }
</style>
